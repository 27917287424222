import React from "react";
import { Badge, IconButton } from "@mui/material";

import Drawer from "@mui/material/Drawer";
import { UilBell } from "@iconscout/react-unicons";

import NotificationsModal from "src/components/Notifications";

type Anchor = "right";

interface INotification {
  notification: number;
}

function HeaderSearch({ notification }: INotification) {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: string, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  return (
    <>
      <Badge badgeContent={notification} color="primary">
        <IconButton
          color="primary"
          onClick={toggleDrawer("right", true)}
        >
          <UilBell />
        </IconButton>
      </Badge>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiPaper-root": {
            height: 450,
            width: 350,
            marginRight:2,
            backgroundColor: "#fff",
            borderRadius: 1,
          },
        }}
      >
        <NotificationsModal anchor="right" toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
}

export default HeaderSearch;
