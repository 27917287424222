import { Box, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";

export enum statusEnum {
  Active = "Active",
  Pending = "Pending",
  Paused = "Paused",
  New = "New",
}

interface StatusProps {
  digitCount?: number;
  name: string;
  icon?: ReactNode;
}

const Status: React.FC<PropsWithChildren<StatusProps>> = ({
  digitCount,
  name,
  icon,
}) => {
  switch (name) {
    case statusEnum.Active:
      return (
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{
            padding: 1,
            background: "rgba(104, 222, 110, 0.2)",
            borderRadius: "13px",
            height: "20px",
          }}
        >
          {icon ? (
            icon
          ) : (
            <img
              src="/static/images/app/greenDot.svg"
              alt="green"
              style={{ width: 5}}
            />
          )}
          <Typography variant="smallerBold" color={"#68DE6E"}>
            {digitCount} {name}
          </Typography>
        </Box>
      );
    case statusEnum.Pending:
      return (
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{
            padding: 1,
            background: "rgba(250, 165, 26, 0.1)",
            borderRadius: "13px",
            height: "20px",
          }}
        >
          {icon ? (
            icon
          ) : (
            <img
              src="/static/images/app/orangedot.svg"
              alt="green"
              style={{ width: 5}}
            />
          )}

          <Typography variant="smallerBold" color={" #FFBA17"}>
            {digitCount} {name}
          </Typography>
        </Box>
      );
    case statusEnum.Paused:
      return (
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{
            padding: 1,
            background: "rgba(249, 44, 44, 0.1)",
            borderRadius: "13px",
            height: "20px",
          }}
        >
          {icon ? (
            icon
          ) : (
            <img
              src="/static/images/app/red.svg"
              alt="green"
              style={{ width: 5}}
            />
          )}

          <Typography variant="smallerBold" color={"#F92C2C"}>
            {digitCount} {name}
          </Typography>
        </Box>
      );
    case statusEnum.New:
      return (
        <Box
          display="flex"
          sx={{
            padding: 0.6,
            background:
              "linear-gradient(90deg, rgba(254,189,47,1) 0%, rgba(220,20,60,1) 35%, rgba(23,40,155,1) 100%)",
            color: "#fff",
            borderRadius: "13px",

            height: "20px",
            marginRight: 1,
          }}
        >
          <Typography variant="smallerBold" color={"#fff"}>
            {name}
          </Typography>
        </Box>
      );
    default:
      return <></>;
  }
};
export default Status;
