import { Fab } from "@mui/material";
import React, {
  FC,
  useState,
  createContext,
  PropsWithChildren,
  ReactElement,
} from "react";
import CircularIndeterminate from "src/components/Spinner";
import {
  GoogleCampaign,
  RESPONSIVESEARCHAD,
  RESPONSIVEDISPLAYAD,
  DISCOVERYMULTIASSETAD,
  VIDEORESPONSIVEAD,
  CLIENT,
} from "src/types/types";

import { FormikErrors } from "formik";
import _ from "lodash";
import { useAppSelector } from "src/hooks/hooks";
import { selectUser } from "src/store/user/userSlice";
import { RIcon } from "src/components/RIcon/RIcon";

type CampaignStepsContextProps = {
  searchCampaignSettings: number;
  currentStep: number;
  currentDropDown: number;
  subMenuValue: number;
  step2Flag: boolean;
  setStep2Flag: (flag: boolean) => void;
  step3Flag: boolean;
  setStep3Flag: (flag: boolean) => void;
  step4Flag: boolean;
  setStep4Flag: (flag: boolean) => void;
  step5Flag: boolean;
  setStep5Flag: (flag: boolean) => void;
  step6Flag: boolean;
  setStep6Flag: (flag: boolean) => void;
  setCurrentDropDown: (num: number) => void;
  setSearchCampaignSettings: (num: number) => void;
  setSubMenuValue: (num: number) => void;
  setCurrentStep: (num: number) => void;
  handleDownwardOrNextButton: (
    currentDropDownx: number,
    isSubmitting: boolean,
    id: string | undefined,
    errors: FormikErrors<GoogleCampaign>,
    values: GoogleCampaign,
    handleSubmit: any
  ) => ReactElement;
};

const CampaignStepsContext = createContext<CampaignStepsContextProps>({
  searchCampaignSettings: 1,
  currentStep: 0,
  currentDropDown: 0,
  subMenuValue: 1,
  step2Flag: false,
  setStep2Flag: () => null,
  step3Flag: false,
  setStep3Flag: () => null,
  step4Flag: false,
  setStep4Flag: () => null,
  step5Flag: false,
  setStep5Flag: () => null,
  step6Flag: false,
  setStep6Flag: () => null,
  setCurrentStep: () => null,
  setSearchCampaignSettings: () => null,
  setSubMenuValue: () => null,
  setCurrentDropDown: () => null,
  handleDownwardOrNextButton: () => <></>,
});

export const useCamapaignStepsContext = () =>
  React.useContext(CampaignStepsContext);

const CampaignStepsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchCampaignSettings, setSearchCampaignSettings] = useState(1);
  const [subMenuValue, setSubMenuValue] = useState(1);
  const [currentDropDown, setCurrentDropDown] = useState(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [step2Flag, setStep2Flag] = useState(false);
  const [step3Flag, setStep3Flag] = useState(false);
  const [step4Flag, setStep4Flag] = useState(false);
  const [step5Flag, setStep5Flag] = useState(false);
  const [step6Flag, setStep6Flag] = useState(false);
  const user = useAppSelector(selectUser);



  const disableNextButton = (
    values: GoogleCampaign,
    errors: FormikErrors<GoogleCampaign>,
    id: string | undefined
  ) => {
    if (id) {
      return false;
    }

    switch (currentStep) {
      case 0:
        if (
          (currentDropDown === RESPONSIVESEARCHAD ||
            currentDropDown === DISCOVERYMULTIASSETAD ||
            currentDropDown === RESPONSIVEDISPLAYAD) &&
          values.title.length > 0 &&
          values.url.length > 0 &&
          !errors.title
        ) {
          setStep2Flag(true);
          return false;
        } else if (currentDropDown === VIDEORESPONSIVEAD && values.url) {
          return false;
        } else {
          setStep2Flag(false);
          return true;
        }

      case 1:
        //@ts-ignore
        if(user?.userRole===CLIENT && currentDropDown === RESPONSIVESEARCHAD  ){
          setStep3Flag(true);
          return false;
        }
       else if (
          currentDropDown === RESPONSIVESEARCHAD &&
          values.bidding.value.length > 0
        ) {
          setStep3Flag(true);
          return false;
        } else if (
          currentDropDown === DISCOVERYMULTIASSETAD &&
          values.location.length > 0 &&
          values.languages?.value.length > 0 &&
          values.bidding?.value.length > 0 &&
          values.budget !== 0 &&
          !errors.location &&
          !errors.languages?.value &&
          !errors.budget &&
          !errors.bidding?.value
        ) {
          setStep3Flag(true);
          return false;
        } else if (
          currentDropDown === RESPONSIVEDISPLAYAD &&
          values.location.length > 0 &&
          values.target.length > 0 &&
          values.languages?.value.length > 0
        ) {
          setStep3Flag(true);
          return false;
        } else if (
          currentDropDown === VIDEORESPONSIVEAD &&
          values.location.length > 0 &&
          values.languages.value.length > 0 &&
          values.bidding.value.length > 0 &&
          values.target.length > 0
        ) {
          setStep3Flag(true);
          return false;
        } else {
          return true;
        }
      case 2:
        if (
          currentDropDown === RESPONSIVESEARCHAD &&
          values.location.length > 0 &&
          values.target.length > 0 &&
          !_.isEmpty(values.languages)
        ) {
          setStep4Flag(true);
          return false;
        } else if (
          currentDropDown === DISCOVERYMULTIASSETAD &&
          // values.audience?.length > 0 &&
          _.isBoolean(values.optimised)
        ) {
          setStep4Flag(true);
          return false;
        } else if (
          currentDropDown === RESPONSIVEDISPLAYAD &&
          values.location.length > 0 &&
          values.bidding.value.length > 0
        ) {
          setStep4Flag(true);
          return false;
        } else if (
          currentDropDown === VIDEORESPONSIVEAD &&
          values.adGroup.length > 0
        ) {
          setStep4Flag(true);
          return false;
        } else {
          setStep4Flag(false);
          return true;
        }
      case 3:
        if (
          currentDropDown === RESPONSIVESEARCHAD &&
          values.adGroup.length > 0 &&
          values.finalUrl.length > 0 &&
          values.headlines.length > 0 &&
          values.descriptions.length > 0 &&
          values?.displayPath1?.length > 0 &&
          values?.displayPath2?.length > 0 &&
          !errors.adGroup &&
          !errors.finalUrl &&
          !errors.headlines &&
          !errors.descriptions &&
          !errors.displayPath1 &&
          !errors.displayPath2
        ) {
          setStep5Flag(true);
          return false;
        } else if (
          currentDropDown === DISCOVERYMULTIASSETAD &&
          values.finalUrl.length > 0 &&
          values.businessName.length > 0 &&
          values.headlines.length >= 3 &&
          values.descriptions.length >= 2 &&
          values.imageUrl1.format !== 0 &&
          values.imageUrl2.format !== 0 &&
          values.logoUrl1.format !== 0 &&
          values.logoUrl2.format !== 0
        ) {
          setStep5Flag(true);
          return false;
        } else if (
          currentDropDown === RESPONSIVEDISPLAYAD &&
          _.isBoolean(values.optimised)
        ) {
          setStep5Flag(true);
          return false;
        } else if (
          currentDropDown === VIDEORESPONSIVEAD &&
          values.youtubeVideoURL.length > 0
        ) {
          setStep5Flag(true);
          return false;
        } else {
          setStep5Flag(false);
          return true;
        }
      case 4:
        if (currentDropDown === RESPONSIVESEARCHAD && values.budget !== 0) {
          setStep6Flag(true);
          return false;
        } else if (
          currentDropDown === RESPONSIVEDISPLAYAD &&
          values.finalUrl.length > 0 &&
          values.imageUrl1.format !== 0 &&
          values.imageUrl2.format !== 0 &&
          values.logoUrl1.format !== 0 &&
          values.logoUrl2.format !== 0 &&
          values.businessName.length > 0 &&
          values.headlines.length >= 3 &&
          values.descriptions.length >= 2
        ) {
          return false;
        } else {
          setStep6Flag(false);
          return true;
        }
      default:
        return true;
    }
  };

  const handleDownwardOrNextButton = (
    currrentDropDownx: number,
    isSubmitting: boolean,
    id: string | undefined,
    errors: FormikErrors<GoogleCampaign>,
    values: GoogleCampaign,
    handleSubmit: any
  ) => {
    if (
      currrentDropDownx === RESPONSIVESEARCHAD &&
      currentStep === 2 &&
      searchCampaignSettings !== 2
    ) {
      return (
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setSearchCampaignSettings(2)}
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(4),
            right: (theme) => theme.spacing(4),
          }}
        >
          Next
          <RIcon icon="UilArrowRight" />
        </Fab>
      );
    } else if (
      currrentDropDownx === RESPONSIVESEARCHAD &&
      currentStep === 3 &&
      subMenuValue !== 2
    ) {
      return (
        <Fab
        variant="extended"
        color="primary"
        onClick={() => setSubMenuValue(subMenuValue + 1)}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        Next
        <RIcon icon="UilArrowRight" />
      </Fab>
      );
    } else if (
      currrentDropDownx === RESPONSIVEDISPLAYAD &&
      currentStep === 1 &&
      searchCampaignSettings !== 2
    ) {
      return (
        <Fab
        variant="extended"
        color="primary"
        onClick={() => setSearchCampaignSettings(2)}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        Next
        <RIcon icon="UilArrowRight" />
      </Fab>
      );
    } else if (
      currrentDropDownx === RESPONSIVEDISPLAYAD &&
      currentStep === 2 &&
      subMenuValue !== 2
    ) {
      return (
        <Fab
        variant="extended"
        color="primary"
        onClick={() => setSubMenuValue(2)}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        Next
        <RIcon icon="UilArrowRight" />
      </Fab>
      );
    } else if (
      currrentDropDownx === DISCOVERYMULTIASSETAD &&
      currentStep === 1 &&
      subMenuValue !== 4
    ) {
      return (
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setSubMenuValue(subMenuValue + 1)}
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(4),
            right: (theme) => theme.spacing(4),
          }}
        >
          Next
          <RIcon icon="UilArrowRight" />
        </Fab>
      );
    } else if (
      currrentDropDownx === DISCOVERYMULTIASSETAD &&
      currentStep === 2 &&
      subMenuValue !== 2
    ) {
      return (
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setSubMenuValue(2)}
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(4),
            right: (theme) => theme.spacing(4),
          }}
        >
          Next
          <RIcon icon="UilArrowRight" />
        </Fab>
      );
    } else if (
      currrentDropDownx === VIDEORESPONSIVEAD &&
      currentStep === 1 &&
      subMenuValue !== 5
    ) {
      return (
        <Fab
        variant="extended"
        color="primary"
        onClick={() => setSubMenuValue(subMenuValue + 1)}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        Next
        <RIcon icon="UilArrowRight" />
      </Fab>
      );
    } else {
      return (
        <Fab
        variant="extended"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting || disableNextButton(values, errors, id)}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
        }}
      >
        {isSubmitting ? "Submitting" : "Next"}
        {isSubmitting ? <CircularIndeterminate /> : <RIcon icon="UilArrowRight" />}
      </Fab>
      );
    }
  };

  return (
    <CampaignStepsContext.Provider
      value={{
        searchCampaignSettings,
        currentStep,
        currentDropDown,
        subMenuValue,
        step2Flag,
        setStep2Flag,
        step3Flag,
        setStep3Flag,
        step4Flag,
        setStep4Flag,
        step5Flag,
        setStep5Flag,
        step6Flag,
        setStep6Flag,
        setSubMenuValue,
        setSearchCampaignSettings,
        handleDownwardOrNextButton,
        setCurrentStep,
        setCurrentDropDown,
      }}
    >
      {children}
    </CampaignStepsContext.Provider>
  );
};

export default CampaignStepsProvider;
