import { Button, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { Dispatch, PropsWithChildren, SetStateAction } from "react";

import Slider from "react-slick";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  Campaign,
  DEFAULT_IMAGE_URL1,
  DISCOVERYMULTIASSETAD,
  FACEBOOK,
  FACEBOOKAD,
  GOOGLE,
  RESPONSIVEDISPLAYAD,
  RESPONSIVESEARCHAD,
  VIDEORESPONSIVEAD,
} from "src/types/types";
import RChip from "../RChip";
import {
  UilCalendarAlt,
  UilCog,
  UilArrowRight,
  UilTimes,
} from "@iconscout/react-unicons";
import RButton, { typeVar } from "../RButton";
import pages from "src/router/routes";
import { useNavigate } from "react-router-dom";
import Status from "../Status";

type CampaignPreviewProps = {
  campaign?: Campaign;
  setIsCampaignPreview: React.Dispatch<React.SetStateAction<boolean>>;
};
const CampaignPreview: React.FC<PropsWithChildren<CampaignPreviewProps>> = ({
  campaign,
  setIsCampaignPreview,
}) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const recommendations = [
    { title: "Recommendation 1", type: "Sitelink" },
    { title: "Recommendation 2", type: "Callout" },
  ];

  const handleTypeOfAd = (id: number) => {
    if (id === FACEBOOKAD) return "Facebook";
    if (id === RESPONSIVESEARCHAD) return "Google Search";
    if (id === RESPONSIVEDISPLAYAD) return "Google Display";
    if (id === VIDEORESPONSIVEAD) return "Google Video";
    if (id === DISCOVERYMULTIASSETAD) return "Google Discovery";
  };

  const calculateBudgetSpent = () => {
    const budget = campaign?.budget;

    const spend = campaign?.spend;

    if (!spend && !budget) {
      const result = (parseInt(spend!) / parseInt(budget!)) * 100;
      return result.toFixed(2);
    }
    return 0;
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <Paper sx={{ padding: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <UilTimes
            onClick={() => setIsCampaignPreview(false)}
            style={{ cursor: "pointer" }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <img
              src="/static/images/app/layers.svg"
              alt="layerss"
              style={{ width: "30px", marginTop: 12 }}
            />
            <Typography
              sx={{ fontSize: 18, fontWeight: 700, marginTop: 1.5 }}
              marginTop={2}
              marginLeft={1}
            >
              {campaign?.title}
            </Typography>
            <Box sx={{ marginTop: 1.7, marginX: 2 }}>
              <Status name={campaign?.status ?? ""} />
            </Box>

            <Box
              sx={{
                display: "flex",
                backgroundColor: "#F3F3F3",
                padding: 0.5,
                borderRadius: "8px",
                marginTop: 1.2,
              }}
            >
              <UilCalendarAlt
                size={15}
                style={{ marginTop: "3px", marginRight: "1px" }}
              />
              <Typography sx={{ fontSize: "14px" }}>
                {campaign?.duration}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{ fontWeight: 700, color: "#8e8e8e", marginTop: 2 }}
            >
              {handleTypeOfAd(campaign?.campaignTypeId ?? 0)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "60px",
            border: "1px solid red",
            borderRadius: 5,
            marginTop: 2,
            paddingTop: 0.7,
            paddingX: 0.7,
            marginBottom: "40px",
          }}
        >
          <ProgressBar
            baseBgColor="#fff"
            height={"44px"}
            customLabel={`Total Spent: ZAR${campaign?.spend}`}
            completed={calculateBudgetSpent()}
            bgColor="linear-gradient(90deg, rgba(254,189,47,1) 0%, rgba(253,29,29,1) 49%, rgba(23,40,155,1) 100%)"
          />
        </Box>
        <Box></Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {campaign?.campaignTypeId !== RESPONSIVESEARCHAD && (
            <Box>
              <div style={{ width: "200px" }}>
                <Slider {...settings}>
                  {campaign?.imageUrl1.url !== DEFAULT_IMAGE_URL1 && (
                    <Box>
                      <img
                        src={campaign?.imageUrl1.url}
                        alt="logo"
                        width={200}
                      />
                    </Box>
                  )}

                  {campaign?.imageUrl2.url !== DEFAULT_IMAGE_URL1 && (
                    <Box>
                      <img
                        src={campaign?.imageUrl2.url}
                        alt="logo"
                        width={200}
                      />
                    </Box>
                  )}
                  {campaign?.logoUrl1.url !== DEFAULT_IMAGE_URL1 && (
                    <Box>
                      <img
                        src={campaign?.logoUrl1.url}
                        alt="logo"
                        width={200}
                      />
                    </Box>
                  )}
                  {campaign?.logoUrl2.url !== DEFAULT_IMAGE_URL1 && (
                    <Box>
                      <img
                        src={campaign?.logoUrl2.url}
                        alt="logo"
                        width={200}
                      />
                    </Box>
                  )}
                </Slider>
              </div>
            </Box>
          )}

          <Box width="70%">
            <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
              Recommendations
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 11,
                color: "#8e8e8e",
                marginTop: "5px",
              }}
            >
              Apply these recommendations to optimize camapign performance
            </Typography>
            <Divider sx={{ color: "#8e8e8e", marginTop: "15px" }} />
            {recommendations.map((recommendation, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                    {recommendation.title}
                  </Typography>
                  <RChip
                    label={recommendation.type}
                    sx={{
                      height: 24,
                      marginLeft: 1,
                      marginTop: -0.3,
                      fontSize: 12,
                      color: "#fff",
                      background: "rgba(254,189,47,1)",
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#000",
                      height: 32,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
      <Box sx={{ marginTop: 2, display: "flex" }}>
        <UilCog
          size={35}
          style={{
            marginTop: 5,
            background: "#f3f3f3",
            padding: 7,
            borderRadius: "70px",
          }}
        />
        <Typography
          sx={{ fontWeight: 700, fontSize: 20, marginTop: 1, marginLeft: 1 }}
        >
          Manage Campaign
        </Typography>
      </Box>
      <Divider sx={{ marginTop: 3, color: "#f3f3f3" }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 16, marginTop: 2, marginLeft: 1 }}
        >
          Review Campaign
        </Typography>
        <RButton
          name="Take me there"
          typeVar={typeVar.Contained}
          endIcon={<UilArrowRight />}
          onEvent={() => {
            if (campaign?.name === GOOGLE) {
              navigate(
                pages.wizard.googleWizardEdit.path.replace(":id", campaign.id)
              );
            } else if (campaign?.name === FACEBOOK) {
              navigate(
                pages.wizard.facebookWizardEdit.path.replace(":id", campaign.id)
              );
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 16, marginTop: 2, marginLeft: 1 }}
        >
          Google Ads Manager
        </Typography>
        <RButton
          name="Take me there"
          typeVar={typeVar.Contained}
          endIcon={<UilArrowRight />}
        />
      </Box>
    </Box>
  );
};

export default CampaignPreview;
