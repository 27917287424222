import React, { PropsWithChildren, ReactNode } from "react";
import {
  FormControl,
  OutlinedInput,
  FormLabel,
  Typography,
} from "@mui/material";
import { UilExclamationCircle } from "@iconscout/react-unicons";
import { FormHelperText, InputAdornment } from "@mui/material";
import { useField } from "formik";

type RInputProps = {
  name: string;
  label?: string;
  icon?: ReactNode;
  placeholder: string;
  height?: string;
  width?: string;
  type?: string;
  disabled?: boolean | false;
  margin?: string;
};

const RInput: React.FC<PropsWithChildren<RInputProps>> = ({
  name,
  label,
  icon,
  height,
  width,
  type,
  placeholder,
  disabled,
  margin
}) => {
  const [field, meta] = useField(name);
  return (
    <FormControl sx={{ marginTop: 2, width: width}}>
      {label && (
        <FormLabel htmlFor={field.name}>
          <Typography variant="smallBold" sx={{ color: "#000" }}>
            {label}
          </Typography>
        </FormLabel>
      )}
      <OutlinedInput
        id={field.name}
        type={type}
        endAdornment={
          icon && <InputAdornment position="end">{icon}</InputAdornment>
        }
        {...field}
        placeholder={placeholder}
        size="small"
        disabled={disabled}
        sx={
          meta.touched && meta.error
            ? {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #F92C2C",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #F92C2C",
                },
                "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #F92C2C",
                },
              }
            : {
                margin: margin,
              }
        }
      />
      {meta.touched && meta.error && (
        <FormHelperText
          id="outlined-weight-helper-text"
          sx={{ display: "flex" }}
        >
          <UilExclamationCircle color="red" size="20px" />{" "}
          <Typography color="red" variant="small" sx={{ marginTop: "" }}>
            {meta.error}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RInput;
