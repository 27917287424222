import {
  Box,
  Divider,
  // InputAdornment,
  MenuItem,
  // OutlinedInput,
  Typography,
} from "@mui/material";

import {
  // UilAngleDown,
  // UilCalendarAlt,
  UilPlus,
  // UilSearch,
} from "@iconscout/react-unicons";
// import Modal from "@mui/material/Modal";
// import { Container } from "@mui/system";
import { RButton } from "src/components";
import { typeVar } from "src/components/RButton";

// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { StyledMenu } from "src/layouts/SearchBarComponent";
// import moment from "moment";
//@ts-ignore
// import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import Active from "../../components/Status/Active";
import Pending from "../../components/Status/Pending";
import Campaigns from "./Campaigns";
// import GroupCampaings from "./groupCampaings";
import CustomContainer from "src/components/CustomContainer";
import { ACTIVE, PENDING } from "src/types/types";
import { getAssignedCampaignsClient, resetCampaigns, selectUserCampaigns } from "src/store/campaignsData/campaignDataSlice";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { selectUser } from "src/store/user/userSlice";
import { getClient, getUserClient } from "src/store/client/clientSlice";
import { StartANewCampaignModal } from "src/components/StartANewCampaignModal/StartANewCampaignModal";
// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-10%, -18%)",
//   width: 400,

//   p: 4,
// };

const CurrentCampaigns = () => {
  // const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startNewCampaign, setStartNewCampaign] = useState(false);

  // const [open, setOpen] = React.useState(false);
  // const [state, setState] = React.useState([
  //   {
  //     startDate: new Date(),
  //     endDate: null,
  //     key: "selection",
  //   },
  // ]);

  const [active, setActive] = useState(true);

  const handleChangeActive = () => {
    setActive(true);
  };

  const handleChangeActiveClose = () => {
    setActive(false);
  };
  // const handleOpen = () => setOpen(true);

  // const handleClose = () => setOpen(false);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const campaigns = useAppSelector(selectUserCampaigns);
  const client = useAppSelector(getClient);


  useEffect(() => {
    dispatch(resetCampaigns())
    dispatch(getUserClient(user?.id as string));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (client) {
      dispatch(getAssignedCampaignsClient(client.id as string));
    }


    return () => {
      dispatch(resetCampaigns());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const opened = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return !campaigns ? (
    <Campaigns />
  ) : (
    <>
      <Box sx={{ background: "#000",paddingBottom:2 }}>
        <CustomContainer >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5Bold" sx={{ color: "#fff" }}>
                Summary
              </Typography>
              <Typography
                variant="small"
                sx={{ color: "rgba(255, 255, 255, 0.6)" }}
              >
                View all your campaigns
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              {/* <RButton
              name="Create a group"
              typeVar={typeVar.OutlinedWhite}
              endIcon={<UilPlus />}
              height={45}
              marginTop={19}
            /> */}
              <RButton
                name="Start a new campaign"
                typeVar={typeVar.Contained}
                endIcon={<UilPlus />}
                onEvent={() => setStartNewCampaign(!startNewCampaign)}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", marginY: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: 2,
                width: "33%",
                borderRadius: "15px",
              }}
            >
              <img
                src="/static/images/app/layersIcon.svg"
                alt="layers"
                style={{ marginRight: 15 }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Campaigns
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
                >
                  {campaigns.filter((c) => c.status === ACTIVE || c.status === PENDING).length}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: 2,
                width: "33%",
                borderRadius: "15px",
              }}
            >
              <img
                src="/static/images/app/activeIcon.svg"
                alt="layers"
                style={{ marginRight: 14 }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Active Campaigns
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
                >
                  {campaigns.filter((c) => c.status === ACTIVE).length}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: 2,
                width: "33%",
                borderRadius: "15px",
              }}
            >
              <img
                src="/static/images/app/layersIcon.svg"
                alt="layers"
                style={{ marginRight: 14 }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Pending
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
                >
                  {campaigns.filter((c) => c.status === PENDING).length}
                </Typography>
              </Box>
            </Box>
          </Box>

        </CustomContainer>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomContainer >
          <Box marginY={1}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", marginRight: 10 }}>
                <Box marginRight={1}>
                  <Typography
                    sx={{
                      backgroundColor: `${active ? "rgba(243, 243, 243, 1)" : ""
                        }`,
                      padding: 0.8,
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                    onClick={handleChangeActive}
                  >
                    Active
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      backgroundColor: `${!active ? "rgba(243, 243, 243, 1)" : ""
                        }`,
                      padding: 0.8,
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                    onClick={handleChangeActiveClose}
                  >
                    Pending
                  </Typography>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="end">
                          <UilSearch />
                        </InputAdornment>
                      }
                      sx={{
                        height: 35,
                        width: 250,
                        marginRight: 2,
                        borderRadius: "13px",
                        color: "#000 !important",
                        "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined":
                          {
                            color: "#000 !important",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E5E4E2",
                          color: "#000 !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#000",
                          color: "#000",
                        },
                        "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#000",
                            color: "#000",
                          },

                        "&.MuiInputbase-input": {
                          color: "#000",
                        },
                      }}
                      placeholder={"Search for a campaign"}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    <RButton
                      name="Search"
                      height={35}
                      typeVar={typeVar.OutlinedBlack}
                    />
                  </Box> */}
              {/* <Box sx={{ display: "flex", marginX: 5 }}>
                    <Typography
                      sx={{
                        marginTop: 0.7,
                        marginRight: 1,
                        color: "#727272",
                        fontWeight: 500,
                      }}
                    >
                      Duration
                    </Typography>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment position="end">
                          <UilCalendarAlt />
                        </InputAdornment>
                      }
                      onClick={handleOpen}
                      sx={{
                        height: 35,
                        width: 300,
                        marginRight: 3,
                        borderRadius: "13px",
                        cursor: "pointer",
                        color: "#000 !important",
                        "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined":
                          {
                            color: "#000 !important",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E5E4E2",
                          color: "#000 !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#000",
                          color: "#000",
                        },
                        "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#000",
                            color: "#000",
                          },

                        "&.MuiInputbase-input": {
                          color: "#000",
                        },
                      }}
                      value={
                        state[0].endDate === null
                          ? "Select a time"
                          : `${moment(state[0].startDate).format(
                              "MMM Do YY"
                            )}-${moment(state[0].endDate).format("MMM Do YY")}`
                      }
                      placeholder={"Select a date"}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <DateRange
                          editableDateInputs={false}
                          //@ts-ignore
                          onChange={(item) => setState([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                        />
                      </Box>
                    </Modal>
                  </Box> */}
              {/* <RButton
                    name="Sort"
                    typeVar={typeVar.OutlinedBlack}
                    endIcon={<UilAngleDown />}
                    //@ts-ignore
                    onEvent={handleClick}
                    height={36}
                  /> */}
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={opened}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Newest
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Oldest
                </MenuItem>

                <MenuItem onClick={handleCloseMenu} disableRipple>
                  A to Z
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Z to A
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
          <Divider />
          {active ? <Active setStartNewCampaign={setStartNewCampaign}/> : <Pending setStartNewCampaign={setStartNewCampaign}/>}
        </CustomContainer>
      </Box>
      <StartANewCampaignModal open={startNewCampaign} onClose={() => setStartNewCampaign(!startNewCampaign)}/>
    </>
  );
};

export default CurrentCampaigns;
