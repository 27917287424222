import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from "@mui/material";
import {
  UilMultiply,
  UilLayerGroup,
  UilArrowRight,
} from "@iconscout/react-unicons";
import pages from "src/router/routes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetCampaign } from "src/store/campaignsData/campaignDataSlice";
import RButton, { typeVar } from "../RButton";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

enum CampaignTypes {
  Facebook = "Facebook",
  Google = "Google"
}

export interface StartANewCampaignModalProps {
  open: boolean;
  onClose: () => void;
}

export const StartANewCampaignModal = (props: StartANewCampaignModalProps) => {
  const [value, setValue] = useState(CampaignTypes.Facebook);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCampaign());
  }, []);

  const handleProceed = () => {
    value === CampaignTypes.Facebook
      ? navigate(pages.wizard.facebookWizard.path)
      : navigate(pages.wizard.googleWizard.path);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={props.open}
      onClose={props.onClose}
      sx={{
        "& .MuiPaper-root": {
          background: "#FFF",
          borderRadius: "20px",
        },
      }}
    >
      <IconButton
        onClick={props.onClose}
        sx={{ alignSelf: "flex-end", mr: 2, mt: 2 }}
      >
        <UilMultiply />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          pt: 0,
          gap: 0,
        }}
      >
        <IconButton
          onClick={props.onClose}
          sx={{
            alignSelf: "flex-start",
            backgroundColor: "#F3F3F3",
            borderRadius: 5,
          }}
        >
          <UilLayerGroup />
        </IconButton>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 700,
            lineHeight: "24px",
            width: "80%",
            pt: 1.5,
            pb: 1,
          }}
        >
          What type of campaign would you like to start?
        </Typography>
        <Typography
          sx={{
            color: "#9B9B9B",
            fontWeight: 500,
            fontSize: "14px",
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          Select one of the two campaign options below:
        </Typography>
        <Divider color="#F3F3F3" />
        <RadioGroup
          row
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 3,
            pt: 4,
            pb: 5,
          }}
        >
          <Card
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: 2,
              boxShadow: "1px 1px 7px 3px rgba(0,0,0,0.07)",
              border:
                value === CampaignTypes.Facebook
                  ? "solid 1px black"
                  : "solid 1px transparent",
            }}
            onClick={() => setValue(CampaignTypes.Facebook)}
          >
            <img src="/static/images/app/facebookLogo.svg" alt="facebook" />
            <Typography
              sx={{ marginTop: 1.5, fontSize: "16px", fontWeight: 700 }}
            >
              Facebook
            </Typography>
            <Radio
              checked={value === CampaignTypes.Facebook}
              value={CampaignTypes.Facebook}
              name="radio-buttons"
              inputProps={{ "aria-label": "Facebook" }}
              sx={{
                alignSelf: "flex-end",
                color: "black !important",
              }}
            />
          </Card>
          <Card
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: 2,
              boxShadow: "1px 1px 7px 3px rgba(0,0,0,0.07)",
              border:
                value === CampaignTypes.Google
                  ? "solid 1px black"
                  : "solid 1px transparent",
            }}
            onClick={() => setValue(CampaignTypes.Google)}
          >
            <img src="/static/images/app/googleLogo.svg" alt="google" />
            <Typography
              sx={{ marginTop: 1.5, fontSize: "16px", fontWeight: 700 }}
            >
              Google
            </Typography>
            <Radio
              checked={value === CampaignTypes.Google}
              value="google"
              name="radio-buttons"
              inputProps={{ "aria-label": "Google" }}
              sx={{
                alignSelf: "flex-end",
                color: "black !important",
              }}
            />
          </Card>
        </RadioGroup>
        <DialogActions>
          <RButton
            typeVar={typeVar.General}
            name="Close"
            onEvent={() => props.onClose()}
          />
          <RButton
            typeVar={typeVar.Contained}
            name="Proceed"
            endIcon={<UilArrowRight />}
            onEvent={() => handleProceed()}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
