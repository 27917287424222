import { FormikErrors, FormikTouched } from "formik";

const CONTAINED = "contained";
const CONTAINEDBLACK = "containedBlack";
const OUTLINEDBLACK = "outlinedBlack";
const OUTLINEDWHITE = "outlinedWhite";

const DEFAULT_IMAGE_URL1 = "/static/images/app/stack.svg";

// This is matched with the database to determine what a user can see/do
const SUPER_USER = "63d8fd8f1c0a6d79b56a9e6b";
const ADMIN = "63d8fd991c0a6d79b56a9e6c";
const CAMPAIGN_MANAGER = "63d8fd9e1c0a6d79b56a9e6d";
const VIEWER = "63d8fd9e1c0a6d79b56a9e6e";
const CLIENT = "63d8fd9e1c0a6d79b56a9e6f";

const ROLENAMEMAPPER: { [key: string]: string } = {
  "63d8fd8f1c0a6d79b56a9e6b": "Super User",
  "63d8fd991c0a6d79b56a9e6c": "Admin",
  "63d8fd9e1c0a6d79b56a9e6d": "Campaign Manager",
  "63d8fd9e1c0a6d79b56a9e6e": "Viewer",
  "63d8fd9e1c0a6d79b56a9e6f": "Client",
};

//Types used by the Google API
const RESPONSIVESEARCHAD = 15;
const RESPONSIVEDISPLAYAD = 19;
const DISCOVERYMULTIASSETAD = 35;
const VIDEORESPONSIVEAD = 30;
//Type used to diff between facebook
const FACEBOOKAD = 1;

//Types used to diff between Google and Facebook
const FACEBOOK = "Facebook";
const GOOGLE = "Google";

const LOCATION = "location";
const LANGUAGE = "language";
const KEYWORDS = "keywords";
const ADS= 'ads'
const BIDDING="bidding"
const BUDGET="budget"

//Types used by file uploader
const IMAGE = "image";
const LOGO = "logo";
const VIDEO = "video";

//Campaign states
const ACTIVE = "Active";
const PENDING = "Pending";
const PAUSED = "Paused";

//Image types
const JPG = 2;
const GIF = 3;
const PNG = 4;

const IMAGEMAPPER: { [key: string]: number } = {
  jpg: JPG,
  png: PNG,
  gif: GIF,
};

const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

const nonSymbolsREGex = /^[a-zA-Z0-9\s]*$/;

const PROVINCES: string[] = [
  "Eastern Cape",
  "Free State",
  "Gauteng",
  "KwaZulu-Natal",
  "Limpopo",
  "Mpumalanga",
  "Northern Cape",
  "North West",
  "Western Cape",
];

export {
  CONTAINED,
  CONTAINEDBLACK,
  OUTLINEDBLACK,
  SUPER_USER,
  ADMIN,
  CAMPAIGN_MANAGER,
  VIEWER,
  CLIENT,
  OUTLINEDWHITE,
  RESPONSIVESEARCHAD,
  RESPONSIVEDISPLAYAD,
  DISCOVERYMULTIASSETAD,
  VIDEORESPONSIVEAD,
  FACEBOOKAD,
  FACEBOOK,
  GOOGLE,
  ROLENAMEMAPPER,
  IMAGE,
  LOGO,
  VIDEO,
  IMAGEMAPPER,
  PROVINCES,
  phoneRegex,
  emailRegex,
  nonSymbolsREGex,
  ACTIVE,
  PENDING,
  PAUSED,
  DEFAULT_IMAGE_URL1,
  LANGUAGE,
  LOCATION,
  KEYWORDS,
  ADS,
  BUDGET,
  BIDDING
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  userRole: string;
  email: string;
  mobileNumber: string;
  firebaseId: string;
  creationTime: string;
};

export type Client = {
  id: string;
  name: string;
  role: string;
  client: string;
  creationTime: string;
  userId: string;
  notification: number;
};

export type Campaign = {
  id: string;
  title: string;
  description: string;
  internalBudget: number;
  duration: string;
  name: string;
  assign: boolean;
  userId: string;
  past: boolean;
  adSet1: { value: string; label: string } | null;
  adSet2: { value: string; label: string } | null;
  adSet1Name: string;
  adSet2Name: string;
  campaignTypeId: number;
  data?: any;
  imageUrl1: FileUploads;
  imageUrl2: FileUploads;
  logoUrl1: FileUploads;
  logoUrl2: FileUploads;
  budget: string;
  status: string;
  spend: string;
};

export interface Page {
  id: string;
  name: string;
}

export type GoogleCampaign = {
  adGroup: string;
  finalUrl: string;
  headlines: string[];
  descriptions: string[];
  longHeadlines: string[];
  languages: { value: string; label: string };
  coa?: { value: string; label: string } | null;
  target: string;
  optimised: boolean;
  audience: string;
  location: string;
  bidding: { value: string; label: string };
  budget: number;
  conversionGoal?: string;
  keywords: string;
  displayPath1: string;
  displayPath2: string;
  budgetFrequency: { value: string; label: string };
  url: string;
  title: string;
  businessName: string;
  youtubeVideoURL: string;
  campaignTypeId: number;
  imageUrl1: FileUploads;
  imageUrl2: FileUploads;
  logoUrl1: FileUploads;
  logoUrl2: FileUploads;
  id?: string;
  status: string;
  internalBudget: number;
};

export type FaceBookCampaign = {
  title: string;
  adSet1Name: string;
  adSet2Name: string;
  adSet1: { label: string; value: string };
  adSet2: { label: string; value: string };
  advantageBudgetActivated: boolean;
  budgetType: { value: string; label: string };
  budget: string;
  bidStrategy: string;
  conversion: string;
  startDate: string;
  endDate?: string | null;
  hasEndDate: boolean;
  callToActionText: { label: string; value: string};
  adSetup: { label: string; value: string };
  adSetupFormat: string;
  coa?: { value: string; label: string } | null;
  destination: string;
  destinationMoreUrl: string;
  destinationDisplayLink: string;
  primaryText: string;
  headline: string;
  description: string;
  imageUrl1: FileUploads;
  imageUrl2: FileUploads;
  logoUrl1: FileUploads;
  logoUrl2: FileUploads;
  id?: string;
  pageId?: string;
  status: string;
  internalBudget: number;
  audienceId?: string;
};

export type ErrorsGoogleType = {
  touched: FormikTouched<GoogleCampaign>;
  errors: FormikErrors<GoogleCampaign>;
};

export type Notification = {
  creationTime: Date;
  description: string;
  firebaseUserId: string;
  id: string;
  isRead: boolean;
  readDateTime: Date | null;
  title: string;
};

export type FileUploads = {
  url: string;
  format: number;
  id: string;
};

export type Audience = {
  clientId: null;
  userId: null;
  name: string;
  location: string;
  ageMin: string;
  ageMax: string;
  genders: number[];
  language: null;
  interest: Interests;
  demographic: null;
  behaviour: Behavior;
  parentalStatuses: null;
  ageRanges: null;
  gendersGoogle: number[];
  incomeRanges: null;
  id: string;
  creationTime: string;
};

export type Interests = {
  id: string;
  name: string;
  audienceSizeLowerBound: number;
  audienceSizeUpperBound: number;
  path: string[];
  description: string;
  topic: string;
};


export type Behavior = {
  id: string;
  name: string;
  type: string;
  path: string[];
  description: string;
  realTimeCluster: boolean;
  audienceSizeLowerBound: number;
  audienceSizeUpperBound: number;
}

export type Demographic = {
  id: string;
  name: string;
  type: string;
  path: string[];
  description: string;
  realTimeCluster: boolean;
  audienceSizeLowerBound: number;
  audienceSizeUpperBound: number;
}
