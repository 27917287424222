import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  UilAngleDown,
  UilFilter,
  UilPlus,
  UilSearch,
  UilTimes,
  UilUserArrows,
} from "@iconscout/react-unicons";
import {
  ADMIN,
  CAMPAIGN_MANAGER,
  CLIENT,
  SUPER_USER,
  User,
  VIEWER,
  emailRegex,
  nonSymbolsREGex,
  phoneRegex,
} from "src/types/types";
import React, { PropsWithChildren, useState } from "react";
import { RButton, RInput } from "src/components";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { typeVar } from "src/components/RButton";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import pages from "src/router/routes";
import { selectUser } from "src/store/user/userSlice";
import {createUser} from "src/store/usersData/userDataSlice";
import CustomContainer from "src/components/CustomContainer";
import { createClient } from "src/store/client/clientSlice";
import Select from "react-select";

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 45,
    minHeight: 35,
    border: "2px solid #eee",
    borderRadius: 10,
    width: 413,
    marginRight: 15,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#eee",
  }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "black",
      fontWeight: 500,
    };
  },
};

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "#000",
    backgroundColor: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
interface SearchBarComponentProps {
  header: string;
  subHeader: string;
  isRevxUsers: boolean;
  buttonText: string;
  searchPlaceholder: string;
  modalTitle: string;
  modalSubTitle: string;
  client?: boolean;
  isActive?: boolean;
  handlePending?: () => void;
  isPending?: boolean;
  handleSort: (value: string) => void;
  handleFilter: (value: string) => void;
  handleUnFilter: (value: string) => void;
  handleSearch: (value: string) => void;
}

const SearchBarComponent: React.FC<
  PropsWithChildren<SearchBarComponentProps>
> = ({
  header,
  subHeader,
  isRevxUsers,
  buttonText,
  searchPlaceholder,
  modalTitle,
  modalSubTitle,
  client,
  handleSort,
  handleFilter,
  handleUnFilter,
  handleSearch,
}) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchInput, setSearchInput] = useState("");
    const [isSearch, setIsSearch] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElFilter, setAnchorElFilter] =
      React.useState<null | HTMLElement>(null);
    const [anchorElFilterRevxPage, setAnchorElFilterRevxPage] =
      React.useState<null | HTMLElement>(null);
    const [widthOfSearchInput, setWidthOfSearchInput] = React.useState("45ch");
    const [isActive, setIsActive] = React.useState(false);
    const [isPending, setIsPending] = React.useState(false);
    const [isPaused, setIsPaused] = React.useState(false);
    const [isCampaignManager, setIsCampaignManager] = React.useState(false);
    const [isViewer, setIsViewer] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isClient, setIsClient] = React.useState(false);
    const [isSuperUser, setIsSuperUser] = React.useState(false);

    const [role, setRole] = React.useState<{ label: string; value: string }>();
    const handleClose = () => {
      setOpen(false);
    };

    const user = useAppSelector(selectUser);

    const opened = Boolean(anchorEl);
    const openFilter = Boolean(anchorElFilter);
    const openRevxFilter = Boolean(anchorElFilterRevxPage);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
      if (client) {
        setAnchorElFilter(event.currentTarget);
      } else {
        setAnchorElFilterRevxPage(event.currentTarget);
      }
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleCloseFilter = () => {
      setAnchorElFilter(null);
      setAnchorElFilterRevxPage(null);
    };

    const validationSchema = client
      ? Yup.object().shape({
        email: Yup.string()
          .matches(emailRegex, "Invalid Email format")
          .min(2, "Please enter at least 3 characters")
          .max(50, "Text too long, Please enter at least 50 characters")
          .required("Please fill in the text"),
        firstName: Yup.string()
          .min(2, "Please enter at least 3 characters")
          .matches(nonSymbolsREGex, "Symbols not allowed")
          .max(50, "Text too long, Please enter at least 50 characters")
          .required("Please fill in the text"),
        lastName: Yup.string()
          .min(2, "Please enter at least 3 characters")
          .matches(nonSymbolsREGex, "Symbols not allowed")
          .max(50, "Text too long, Please enter at least 50 characters!")
          .required("Please fill in the text"),
        companyName: Yup.string()
          .matches(nonSymbolsREGex, "Symbols not allowed")
          .min(2, "Please enter at least 3 characters")
          .max(50, "Text too long, Please enter at least 50 characters")
          .required("Please fill in the text"),
        mobileNumber: Yup.string()
          .matches(phoneRegex, "Invalid Phone Number")
          .min(10, "Please enter at least 3 characters")
          .max(10, "Number too long, Please enter at least 10 characters")
          .required("Please fill in the text"),
      })
      : Yup.object().shape({
        email: Yup.string()
          .min(2, "Please enter at least 3 characters").matches(emailRegex, "Invalid Email format")
          .max(50, "Too Long!")
          .required("Required"),
        firstName: Yup.string()
          .min(2, "Please enter at least 3 characters")
          .max(50, "Text too long, Please enter at least 50 characters").matches(nonSymbolsREGex, "Symbols not allowed")
          .required("Required"),
        lastName: Yup.string()
          .min(2, "Please enter at least 3 characters")
          .max(50, "Text too long, Please enter at least 50 characters").matches(nonSymbolsREGex, "Symbols not allowed")
          .required("Required"),
        mobileNumber: Yup.string()
          .min(10, "Too Short!")
          .max(10, "Number too long, Please enter at least 10 characters").matches(phoneRegex, "Invalid Phone Number")
          .required("Required"),
        role: Yup.object().required(),
      });
    const initialValues = client
      ? {
        email: "",
        mobileNumber: "",
        firstName: "",
        lastName: "",
        companyName: "",
        role: {
          label: "Client",
          value: CLIENT,
        }
      }
      : {
        email: "",
        mobileNumber: "",
        firstName: "",
        lastName: "",
        role: {},
      };

    const handleSearchClicked = () => {
      handleSearch(searchInput);
      setIsSearch(false);
    };
    const handleClear = () => {
      setWidthOfSearchInput("45ch");
      setSearchInput("");
      setIsSearch(true);
      handleSearch("");
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    };

    const handlePending = (status: string) => {
      if (isPending) {
        handleUnFilter("Pending");
        setIsPending(false);
      } else {
        handleFilter(status);
        setIsPending(true);
      }
    };

    const handleActive = (status: string) => {
      if (isActive) {
        handleUnFilter("Active");
        setIsActive(false);
      } else {
        handleFilter(status);
        setIsActive(true);
      }
    };

    const handlePaused = (status: string) => {
      if (isPaused) {
        handleUnFilter("Paused");
        setIsPaused(false);
      } else {
        handleFilter(status);
        setIsPaused(true);
      }
    };

    const handleManager = (userRole: string) => {
      if (isCampaignManager) {
        handleUnFilter(userRole);
        setIsCampaignManager(false);
      } else {
        handleFilter(userRole);
        setIsCampaignManager(true);
      }
    };

    const handleViewer = (userRole: string) => {
      if (isViewer) {
        handleUnFilter(userRole);
        setIsViewer(false);
      } else {
        handleFilter(userRole);
        setIsViewer(true);
      }
    };

    const handleSuperUser = (userRole: string) => {
      if (isSuperUser) {
        handleUnFilter(userRole);
        setIsSuperUser(false);
      } else {
        handleFilter(userRole);
        setIsSuperUser(true);
      }
    };

    const handleClient = (userRole: string) => {
      if (isClient) {
        handleUnFilter(userRole);
        setIsClient(false);
      } else {
        handleFilter(userRole);
        setIsClient(true);
      }
    };

    const handleAdmin = (userRole: string) => {
      if (isAdmin) {
        handleUnFilter(userRole);
        setIsAdmin(false);
      } else {
        handleFilter(userRole);
        setIsAdmin(true);
      }
    };
    const handleSelectChange = (selectedOption: any) => {
      setRole(selectedOption);
    };

    const handleAssignUsersRoles = (loggedInUserRole: string | undefined) => {
      if (loggedInUserRole === SUPER_USER) {
        return (
          <Select
            menuPlacement="top"
            options={client ? [{
              label: "Client",
              value: CLIENT,
            }] : [
              {
                label: "Super User",
                value: SUPER_USER,
              },
              {
                label: "Admin",
                value: ADMIN,
              },
              {
                label: "Campaign Manager",
                value: CAMPAIGN_MANAGER,
              },
              {
                label: "Client",
                value: CLIENT,
              },
              {
                label: "Viewer",
                value: VIEWER,
              },
            ]}
            onChange={handleSelectChange}
            value={role}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={customStyles}
          />
        );
      } else if (loggedInUserRole === ADMIN) {
        return (
          <Select
            menuPlacement="top"
            options={client ? [{
              label: "Client",
              value: CLIENT,
            }] : [
              {
                label: "Admin",
                value: ADMIN,
              },
              {
                label: "Campaign Manager",
                value: CAMPAIGN_MANAGER,
              },
              {
                label: "Client",
                value: CLIENT,
              },
            ]}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={role}
            onChange={handleSelectChange}
            styles={customStyles}
          />
        );
      } else if (loggedInUserRole === CAMPAIGN_MANAGER) {
        return (
          <Select
            menuPlacement="top"
            options={client ? [{
              label: "Client",
              value: CLIENT,
            }] : [
              {
                label: "Campaign Manager",
                value: CAMPAIGN_MANAGER,
              },
              {
                label: "Client",
                value: CLIENT,
              },
            ]}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={role}
            onChange={handleSelectChange}
            styles={customStyles}
          />
        );
      } else {
        return null;
      }
    };

    return (
      <CustomContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            background: "#000",
            marginTop: 3,
            marginBottom: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5Bold" color="#fff" mb={0.5}>
              {header}
            </Typography>
            <Typography variant="small" color="#fff">
              {subHeader}
            </Typography>
          </Box>

        </Box>
        <Divider
          sx={{ marginTop: 2, backgroundColor: "rgba(255, 255, 255, 0.15)" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 1.5,
            marginTop: 3,
            marginBottom: 3,
            flexWrap: "wrap"
          }}
        >
          <Box display="flex" gap={1.5} sx={{flexWrap: "wrap"}}>
          <Box display="flex" gap={1.5}>
            <FormControl sx={{width: "50ch"}}>
              <OutlinedInput
                type="text"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="end">
                    <UilSearch />
                  </InputAdornment>
                }
                value={searchInput}
                sx={{
                  height: 45,
                  // marginRight: "10px",
                  borderRadius: "20px",
                  color: "#fff !important",
                  backgroundColor: "#E7E5E519",
                  borderColor: "rgba(255, 255, 255, 0.25)",

                  "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined": {
                    color: "#fff !important",
                    borderColor: "rgba(255, 255, 255, 0.25)",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.25)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.25)",
                    color: "#000",
                  },
                  "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.25)",
                    color: "#000",
                  },

                  "&.MuiInputbase-input": {
                    borderColor: "rgba(255, 255, 255, 0.25)",
                    color: "#000",
                  },
                }}
                onChange={handleSearchChange}
                placeholder={searchPlaceholder}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "search",
                }}
              />
            </FormControl>
            {isSearch ? (
              <RButton
                name={"Search"}
                typeVar={typeVar.OutlinedWhite}
                onEvent={handleSearchClicked}
              />
            ) : (
              <RButton
                name="Clear"
                typeVar={typeVar.OutlinedWhite}
                onEvent={handleClear}
              />
            )}
            </Box>
            {!client && <RButton
              name="Filter"
              typeVar={typeVar.OutlinedWhite}
              startIcon={<UilFilter />}
              //@ts-ignore
              onEvent={handleFilterClick}
            />}
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElFilter}
              open={openFilter}
              onClose={handleCloseFilter}
            >
              <MenuItem disableRipple>
                <FormGroup>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleActive("Active")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                          checked={isActive}
                        />
                      }
                      label="Active"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handlePending("Pending")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                          checked={isPending}
                        />
                      }
                      label="Pending"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handlePaused("Paused")}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isPaused}
                      label="Paused"
                    />
                  </MenuItem>
                </FormGroup>
              </MenuItem>
            </StyledMenu>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElFilterRevxPage}
              open={openRevxFilter}
              onClose={handleCloseFilter}
            >
              <MenuItem disableRipple>
                <FormGroup>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleViewer(VIEWER)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isViewer}
                      label="Viewer"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAdmin(ADMIN)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isAdmin}
                      label="Admin"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleManager(CAMPAIGN_MANAGER)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isCampaignManager}
                      label="Campaign Manager"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleSuperUser(SUPER_USER)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isSuperUser}
                      label="Super User"
                    />
                  </MenuItem>
                  <MenuItem disableRipple>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleClient(CLIENT)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: "30px",
                              height: "30px",
                            },
                          }}
                        />
                      }
                      checked={isClient}
                      label="Client"
                    />
                  </MenuItem>
                </FormGroup>
              </MenuItem>
            </StyledMenu>
            <RButton
              name="Sort"
              typeVar={typeVar.OutlinedWhite}
              endIcon={<UilAngleDown />}
              //@ts-ignore
              onEvent={handleClick}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={opened}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleSort("newest")} disableRipple>
                Newest
              </MenuItem>
              <MenuItem onClick={() => handleSort("oldest")} disableRipple>
                Oldest
              </MenuItem>

              <MenuItem onClick={() => handleSort("a-to-z")} disableRipple>
                A to Z
              </MenuItem>
              <MenuItem onClick={() => handleSort("z-to-a")} disableRipple>
                Z to A
              </MenuItem>
            </StyledMenu>
            </Box>
            <RButton
            name={buttonText}
            typeVar={typeVar.Contained}
            endIcon={<UilPlus />}
            onEvent={() => setOpen(true)}
          />
          <Dialog open={open} onClose={handleClose}>
            <Box
              sx={{
                width: 450,
                backgroundColor: "#fff",
                padding: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <UilUserArrows color="#000" background="#808080" />
                <UilTimes
                  color="#000"
                  onClick={handleClose}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6Bold">{modalTitle}</Typography>
                <Typography variant="smaller" sx={{ color: "#808080" }}>
                  {modalSubTitle}
                </Typography>
              </Box>
              <Divider
                sx={{
                  marginTop: 0,
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    let response;
                    if (!client) {
                      response = await dispatch(
                        createUser({ ...values, role: role?.value })
                      );
                    } else {
                      response = await dispatch(
                        createUser({ ...values, role: role?.value })
                      );
                      if (response.meta.requestStatus === "fulfilled") {
                        toast.success(`Client created successfully!`);
                      } else {
                        toast.error(`Client could not be created.`);
                      }
                      const payload: any = response.payload;
                      const data: User = payload.data;
                      response = await dispatch(
                        createClient({ ...values, role: values.role.value, userId: data.id })
                      );

                      if (response.meta.requestStatus === "fulfilled") {
                        toast.success(
                          `${client ? "Client" : "User"} created successfully!`
                        );
                        navigate(
                          client
                            ? pages.revx.clientProfile.path
                            : pages.revx.userProfile.path
                        );
                      } else {
                        toast.error(
                          `${client ? "Client" : "User"}  could not be created.`
                        );
                      }
                    }
                  }}
                >
                  {({ isSubmitting, errors, touched }) => (
                    <Form style={{ display: "flex", flexDirection: "column" }}>
                      <RInput
                        placeholder="Enter your first name"
                        label="First Name"
                        name="firstName"
                        type="text"
                      />
                      <RInput
                        placeholder="Enter your last name"
                        label="Last Name"
                        name="lastName"
                        type="text"
                      />
                      <RInput
                        placeholder="Enter your email address"
                        label="Email Address"
                        name="email"
                        type="text"
                      />
                      {client && (
                        <RInput
                          placeholder="Enter your company name"
                          label="Company Name"
                          name="companyName"
                          type="text"
                        />
                      )}
                      <RInput
                        placeholder="Enter your mobile number"
                        label="Mobile Number"
                        name="mobileNumber"
                        type="text"
                      />


                      <Typography
                        variant="smallBold"
                        sx={{ color: "#000", marginTop: 2 }}
                      >
                        Role
                      </Typography>
                      {handleAssignUsersRoles(user?.userRole)}

                      <RButton
                        typeVar={typeVar.Contained}
                        name={client ? "Add Client" : "Add User"}
                        endIcon={<UilPlus />}
                        isSubmitting={isSubmitting}
                        width={180}
                      />
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Dialog>
          </Box>
      </CustomContainer>
    );
  };

export default SearchBarComponent;
