import React from "react";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import router from "src/router/router";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "./hooks/hooks";
import { useRoutes } from "react-router-dom";
import { selectTheme } from "./store/theme/themeSlice";
import { themeNames } from "./store/theme/themeSlice.contracts";
import AuthProvider from "./contexts/UserContext";
import CampaignStepsProvider from "./contexts/GoogleCampaignStepsContext";
import firebase from "./firebase";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const content = useRoutes(router);
  const theme = useAppSelector(selectTheme);
  
  return (
    <ThemeProvider>
      <AuthProvider>
      <CampaignStepsProvider>
        <CssBaseline />
        <ToastContainer
          theme={theme === themeNames.dark ? "dark" : "light"}
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
        />
        {content}
        </CampaignStepsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
