import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Status, { statusEnum } from "./index";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { RButton, RChip } from "src/components";
import _ from "lodash";
import { typeVar } from "src/components/RButton";
import GridItem from "../GridItem";
import { Campaign, PENDING } from "src/types/types";
import CampaignCard from "../CampaignCard";
import { useAppSelector } from "src/hooks/hooks";
import { selectUserCampaigns } from "src/store/campaignsData/campaignDataSlice";
import { useNavigate } from "react-router-dom";
import pages from "src/router/routes";
import CampaignPreview from "../CampaignPreview";

export interface PendingProps {
  setStartNewCampaign: React.Dispatch<React.SetStateAction<boolean>>
}

const Pending = ({setStartNewCampaign}: PendingProps) => {
  const campaigns = useAppSelector(selectUserCampaigns);
  const navigate = useNavigate();
  const [isCampaignPreview, setIsCampaignPreview] = useState(false);
  const [campaign, setCampaign] = useState<Campaign>();

  const handleSetPreview = (obj: Campaign) => {
    setCampaign(obj);
    setIsCampaignPreview(true);
  };
  return (
    <Box sx={{ flexGrow: 1, marginTop: 1 }}>
      {_.isEmpty(campaigns.filter(c => c.status === PENDING)) ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img
              src="/static/images/app/activeEmpty.svg"
              alt="empty"
              height={250}
            />
            <Typography variant="h6Bold" sx={{ textAlign: "center" }}>
              No pending campaigns yet
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                width: 400,
                color: "rgba(155, 155, 155, 1)",
              }}
            >
              Create and start a new campaign to track your marketing progress.
            </Typography>
            <Box display="flex" justifyContent="center" marginTop={1}>
              <RButton
                name="Start a new campaign"
                typeVar={typeVar.ContainedBlack}
                onEvent={() => setStartNewCampaign(true)}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        isCampaignPreview ? (
          <CampaignPreview
            campaign={campaign}
            setIsCampaignPreview={setIsCampaignPreview}
          />
        ):
        <Box sx={{ flexGrow: 1, marginTop: 1 }}>
        <Grid container spacing={2}>
          {campaigns.filter(c => c.status === PENDING)
            .map((obj: Campaign, index: number) => {
              return (
                <Grid item sm={6} xs={12} md={4} lg={3} key={index} onClick={() => handleSetPreview(obj)}>
                  <GridItem color="#fff">
                    <CampaignCard
                      socialMediaLabel={obj.name}
                      isMonitor={true}
                      campaignDuration={obj.duration}
                      campaignHeader={obj.title}
                      campaignSubHeader={obj.title}
                      campaignTypeId={obj.campaignTypeId}
                      status={obj.status}
                    />
                  </GridItem>
                </Grid>
              );
            })}
        </Grid>
      </Box>
      )}
    </Box>
  );
};
export default Pending;
