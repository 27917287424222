import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateHttpClient } from "src/providers/dataProvider";
import { Audience, Behavior, Demographic, Interests } from "src/types/types";
import { RootState } from "../store";
import { IAudiencelice } from "./audienceSlice.contracts";

const initialState: IAudiencelice = {
  audience: null,
  audiences: [],
  loading: false,
  interests: [],
  behaviours: [],
  demographics: []
};

const httpClient = CreateHttpClient<Audience>("Audience");
const httpClientInterests = CreateHttpClient<Interests>("Interests");
const httpClientBehaviours = CreateHttpClient<Behavior>("Behavior");
const httpClientDemographics = CreateHttpClient<Demographic>("Demographics");



export const getAudiences = createAsyncThunk(
  "audience/getAudiences",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return await httpClient.getAll(state.user.authToken as string);
  }
);

export const getInterests = createAsyncThunk(
  "audience/getInterests",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return await httpClientInterests.getAllCustom(state.user.authToken as string, "Interests", "Audience");
  }
);


export const getBehaviours = createAsyncThunk(
  "audience/getBehaviors",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return await httpClientBehaviours.getAllCustom(state.user.authToken as string, "Behaviors", "Audience");
  }
);

export const getDemographics = createAsyncThunk(
  "audience/getDemographics",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return await httpClientDemographics.getAllCustom(state.user.authToken as string, "Demographics", "Audience");
  }
);

export const createAudience = createAsyncThunk(
  "audience/createAudience",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    return await httpClient.create(params, state.user.authToken as string);
  }
);

const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    resetAudience(state) {
      state.audiences = null;
      state.audience = null;
    },

  },
  extraReducers: (builder) => {
    //Cases for getAudiences
    builder.addCase(getAudiences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAudiences.fulfilled, (state, action) => {
      state.audiences = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAudiences.rejected, (state) => {
      state.loading = false;
    });
    //Cases for getting interests
    builder.addCase(getInterests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInterests.fulfilled, (state, action) => {
      state.interests = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getInterests.rejected, (state) => {
      state.loading = false;
    });
    //Cases for getting behaviours
    builder.addCase(getBehaviours.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBehaviours.fulfilled, (state, action) => {
      state.behaviours = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getBehaviours.rejected, (state) => {
      state.loading = false;
    });
    //Cases for getting behaviours
    builder.addCase(getDemographics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDemographics.fulfilled, (state, action) => {
      state.demographics = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getDemographics.rejected, (state) => {
      state.loading = false;
    });
    //Cases for creating an audience
    builder.addCase(createAudience.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAudience.fulfilled, (state, action) => {
      state.audience = action.payload.data;
      state.loading = false;
    });
    builder.addCase(createAudience.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default audienceSlice.reducer;

export const { resetAudience } =
  audienceSlice.actions;

export const selectAudiences = (state: RootState) => state.audience.audiences;

export const selectInterests = (state: RootState) => state.audience.interests;

export const selectBehaviours = (state: RootState) => state.audience.behaviours;

export const selectDemographics = (state: RootState) => state.audience.demographics;

export const audienceLoading = (state: RootState) => state.audience.loading;

