import React from 'react';
import * as Icons from '@iconscout/react-unicons';
import { SvgIconProps, SvgIcon } from '@mui/material';

export interface IconProps extends SvgIconProps {
  icon: keyof typeof Icons;
}

export const RIcon = React.forwardRef(function Icon(IconProps: IconProps, ref) {
  const IconComponent = Icons[IconProps.icon as keyof typeof Icons];
  return <SvgIcon {...IconProps} component={IconComponent} />;
});