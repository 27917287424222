import * as React from "react";
import { useEffect } from "react";
import { useAppSelector } from "src/hooks/hooks";
import { selectUser } from "src/store/user/userSlice";
import { IUser } from "src/store/user/userSlice.contracts";
import { ADMIN, CAMPAIGN_MANAGER, CLIENT, SUPER_USER } from "src/types/types";
import { useLocation, NavLink as RouterLink } from "react-router-dom";
import pages from "src/router/routes";
import firebase from "src/firebase";
import Inbox from "./Buttons/Inbox";
import Notification from "./Buttons/Notification";
import User from "./Buttons/User";
import {
  Box,
  Button,
  Typography,
  Toolbar,
  Stack,
  Divider,
} from "@mui/material";
import {
  UilUserArrows,
  UilUsersAlt,
  UilLayers,
  UilLayersSlash,
} from "@iconscout/react-unicons";

const Header = () => {
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const [notification, setnotification] = React.useState<any>(null);

  useEffect(() => {
    let unsubscribe: any;
    if (user?.firebaseId) {
      const notificationsRef = firebase
        .firestore()
        .collection("notifcations")
        .where("firebaseUserId", "==", user?.firebaseId);
      unsubscribe = notificationsRef
        .where("isRead", "==", false)
        .onSnapshot((snapshot: any) => {
          setnotification(snapshot.size);
        });
    } else {
      console.log("anon_user");
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  interface MenuItemProps {
    title: string;
    path: string;
    icon: React.ReactNode;
  }

  const MenuItem = ({ title, path, icon }: MenuItemProps) => {
    return (
      <Box>
        <Button
          component={RouterLink}
          to={path}
          startIcon={icon}
          sx={{
            background:
              location.pathname === path ? "rgba(255, 255, 255, 0.15)" : null,
          }}
        >
          <Typography variant="small" color="#fff">
            {title}
          </Typography>
        </Button>
      </Box>
    );
  };

  const Clients = () => (
    <MenuItem
      title="Clients"
      path={pages.revx.client.path}
      icon={<UilUserArrows />}
    />
  );
  const RevXUsers = () => (
    <MenuItem
      title="RevX Users"
      path={pages.revx.revxusers.path}
      icon={<UilUsersAlt />}
    />
  );
  const AllCampaigns = () => (
    <MenuItem
      title="Campaigns"
      path={pages.revx.allCampaigns.path}
      icon={<UilLayers />}
    />
  );
  const CurrentCampaigns = () => (
    <MenuItem
      title="Current Campaigns"
      path={pages.revx.currentCampaigns.path}
      icon={<UilLayers />}
    />
  );
  const PastCampaigns = () => (
    <MenuItem
      title="Past Campaigns"
      path={pages.revx.pastCampaigns.path}
      icon={<UilLayersSlash />}
    />
  );

  const headerSwitcher = (user: IUser | null) => {
    switch (user?.userRole) {
      case SUPER_USER:
        return (
          <>
            <Clients />
            <RevXUsers />
            <AllCampaigns />
          </>
        );
      case ADMIN:
        return (
          <>
            <Clients />
            <AllCampaigns />
          </>
        );
      case CLIENT:
        return (
          <>
            <CurrentCampaigns />
            <PastCampaigns />
          </>
        );
      default:
        return <AllCampaigns />;
    }
  };

  return (
    <Toolbar disableGutters>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: 1,
          paddingBottom: 1,
          width: "100%",
          backgroundColor: "#000",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Box
            component="img"
            alt="RevX logo"
            src="/static/images/app/revx-logo-white.png"
            sx={{
              height: "40px",
              pr: "80px",
            }}
          />
          {headerSwitcher(user)}
        </Stack>
        <Stack direction="row" spacing={1.5}>
          <Notification notification={notification} />
          <Inbox />
          <Divider orientation="vertical" />
          <User />
        </Stack>
      </Box>
    </Toolbar>
  );
};
export default Header;
