import { Box, CardContent, Typography } from "@mui/material";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { RChip } from "src/components";
import Status from "../../components/Status";
import React, { PropsWithChildren } from "react";

import {
  DISCOVERYMULTIASSETAD,
  RESPONSIVEDISPLAYAD,
  RESPONSIVESEARCHAD,
  VIDEORESPONSIVEAD,
} from "src/types/types";

type CampaignCardProps = {
  campaignHeader: string;
  campaignSubHeader: string;
  campaignDuration: string;
  socialMediaLabel: string;
  status: string;
  isMonitor: boolean;
  campaignTypeId: number;
};

const CampaignCard: React.FC<PropsWithChildren<CampaignCardProps>> = ({
  campaignDuration,
  campaignHeader,
  campaignSubHeader,
  status,
  socialMediaLabel,
  campaignTypeId,
}) => {
  const getCampaignType = (campaignTypeId: number) => {
    switch (campaignTypeId) {
      case RESPONSIVEDISPLAYAD:
        return "Display";
      case RESPONSIVESEARCHAD:
        return "Search";
      case VIDEORESPONSIVEAD:
        return "Video";
      case DISCOVERYMULTIASSETAD:
        return "Discovery";
      default:
        return "";
    }
  };
 
  return (
    <CardContent 
      sx={{
        display:"flex", 
        flexDirection:"column", 
        cursor: "pointer",
        gap: 1.5
      }}
    >
      <Box display="flex">
        <Status name={status}/>
      </Box>
      <Box display={"flex"} flexDirection="column">
        <Typography variant="h6Bold">
          {campaignHeader?.substring(0, 16)}
          {campaignHeader?.length >= 16 && "..."}
        </Typography>
        <Typography variant="small" sx={{ color: "#9B9B9B"}}>
          {campaignSubHeader?.substring(0, 19)}
          {campaignSubHeader?.length >= 19 && "..."}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        sx={{
          backgroundColor: "#F3F3F3",
          padding: 0.5,
          borderRadius: "8px",
        }}
      >
        <UilCalendarAlt size={15}/>
        <Typography sx={{ fontSize: "14px" }}>
          {campaignDuration}
        </Typography>
      </Box>
      <Box display="flex" gap={1}>
        <RChip
          sx={{
            color: "#fff",
            backgroundColor: "#000",
            fontWeight: 700,
            height: 25,
            padding: 1,
            borderRadius: 0.5
          }}
          label={socialMediaLabel}
        />
        {campaignTypeId !== 1 && (
          <RChip
            label={getCampaignType(campaignTypeId)}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              fontWeight: 700,
              height: 25,
              padding: 1,
              borderRadius: 0.5
            }}
          />
        )}
      </Box>
    </CardContent>
  );
};

export default CampaignCard;
